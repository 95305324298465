.gameScoreCard {
    width: 558px;
    height: 435px;
    border: 1px solid #E2E5EA;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}

.scoreDiv {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}