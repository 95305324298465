.mainFooterDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1280;
  // flex: auto;
}

.footerDiv {
  // left: 0;
  // right: 0;
  // bottom: 0;
  // width: 1280px;
  position: relative;
  background: #ffffff;
  justify-content: space-between;
  // align-items: center;
  display: flex;
  // flex: auto;
  flex-direction: column;
}

.listsWrapper {
  display: flex;
  flex: auto;
  flex-direction: row;
  justify-content: space-around;
}

.list1position {
  // position: absolute;
  width: 302px;
  height: 323px;
}

.fadedText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 138% */

  /* Text Color/Low Contrast */

  color: #828d9e;
}

.strongText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 156% */

  /* Text Color/Normal */

  color: #1e354e;
}

.paymentList {
  justify-content: space-between;
  display: flex;
  text-decoration:none;
  padding-left: 0;

  li {
    display: inline;
  }
}

.socialList {
  justify-content: space-between;
  align-items: baseline;
  display: flex;
  width: 323px;

  li {
    display: inline;
  }
}

.copywrite {
  max-width: 605px;
  align-items: center;
}

.copyRightDiv {
  flex: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  // position: absolute;
  // width: 605px;
  // height: 22px;
  // left: 320px;
  // top: 461px;
}
