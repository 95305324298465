.mainFeaturedProductsDiv {
  // background-color: aquamarine;
  display: flex;
  justify-content: center;
  align-items: center;
  scale: 0.8;
}

.innerDiv {
  width: 1279px;
  height: 701px;
  // background-color: burlywood;
  margin-bottom: 12px;
}

.headerTextFeatured {
  /* Heading 2 / SemiBold */

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  /* identical to box height */

  /* Text Color/Normal */

  color: #1e354e;
}

.normalTextFeatured {
  /* Heading 5 / Regular */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* or 138% */

  /* Text Color/Low Contrast */

  color: #828d9e;
}

.itemsDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  // height: 598px;
}
.itemsMainDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // flex-wrap: wrap;
  flex-direction: row;
}
