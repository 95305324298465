
.tableNav {
    @keyframes animateBorder {
        from {
            border-bottom: 0px solid #00B5FF;
            padding-bottom: 0px 
        }
    
        to {
            border-bottom: 2px solid #00B5FF;
            padding-bottom: 3px // text-decoration-color:#00B5FF;
        }
    }

    li {
        display: inline;
        padding-right: 30px;
        margin-top: 30px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
        color: #1E354E;
        cursor: pointer;

    }

    li:hover {
        border-bottom: 2px solid #00B5FF;
        padding-bottom: 3px ;
        animation-name:animateBorder;
        animation-duration: 0.3s;
        // animation-timing-function: linear;
        }
    
}

.teamsTable {
    width: 100%;

    tr {
        border-bottom: 1px solid #ddd;
        // margin-top: 5px;
    }

    th {
        width: 5%;
        padding: 15px;
        /* Heading 5 / Medium */

        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        /* identical to box height, or 138% */


        /* Text Color/Normal */

        color: #1E354E;
    }

    td {
        padding: 15px;
        /* Heading 5 / Regular */

        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        /* identical to box height, or 138% */


        /* Text Color/Normal */

        color: #1E354E;
    }

    .teamNames {
        width: 60%;
    }
}