.comingGameSmallDiv {
    // background-color: orange;

    width: 100%;
    height: 493px;
    border: 1px solid #E2E5EA;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.smallTimeDiv {
    display: flex;
    width: 200px;
    justify-content: space-evenly;
}

.heading4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    /* identical to box height, or 156% */

    text-align: center;

    /* Text Color/Normal */

    color: #1E354E;
}

.heading3 {
    /* Heading 3 / SemiBold */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */

    text-align: center;

    /* Text Color/Normal */

    color: #1E354E;
}

.heading5 {
    /* Heading 5 / Medium */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 138% */

    text-align: center;

    /* Text Color/Normal */

    color: #1E354E;

}

.borderedField {
    padding: 15px 74px;
    gap: 10px;
    width: 197px;
    height: 52px;
    border: 1px solid #E2E5EA;
    border-radius: 8px;
}
.mt20{
    margin-top: 20px;
}