.step-item {
    @apply relative flex flex-col justify-center items-center w-36 ;
  }
  .step-item:not(:first-child):before {
    @apply content-[''] bg-slate-200 absolute w-full h-[3px] right-2/4 top-1/4 -translate-y-2/4;
  }
  .step {
    @apply w-10 h-10 flex items-center justify-center z-10 relative border border-[#000000] bg-white rounded-full font-semibold text-[[#000000]];
  }
  .active .step {
    @apply bg-white;
    @apply border-[#00B5FF];
    @apply border-2;
    @apply text-[#00B5FF]
  }
  .complete .step {
    @apply bg-white;
    @apply border-[#000000];
    @apply border-2;
  }
  /* // .complete p {
  //   @apply text-white;
  // } */
  .complete:not(:first-child):before,
  .active:not(:first-child):before {
    @apply bg-[#000000];
  }