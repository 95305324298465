.comingGamesDiv {
    width: 1280px;
    height: 547px;
    display: flex;
    scale: 0.8;
}

.leftDivComingGames {
    width: 50%;
    height: 100%;
    // background-color: aquamarine;
    // align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.rightDivComingGames {
    width: 50%;
    height: 100%;
    // background-color: orange;
    // display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

