.featuredItem {
  box-sizing: border-box;

  width: 193px;
  height: 287px;

  /* Border Color/#1 */

  border: 1px solid #e2e5ea;
  border-radius: 8px;
  // flex:1
  margin: 12px;
  display: flex;
  flex-direction: column;

  text-decoration: none;
}

.productImg {
  margin: 40px;
}

.productContent {
  /* Heading 5 / Regular */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  /* Text Color/Low Contrast */

  color: #828d9e;
}

.productType {
  /* Heading 5 / Medium */

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  /* Text Color/Normal */

  color: #1e354e;
}

.productPrice {
  /* Heading 5/SemiBold */

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  /* Text Color/Normal */

  color: #1e354e;
}
.productSpecs {
  margin-left: 20px;
}
