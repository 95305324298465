.bigDiv {
    // margin-top: 50px;
    width: 1280px;
    // height: 650px;
    display: flex;
    flex-direction: column;
    scale: 0.8;
    .buttonDiv{
        justify-content: center;
        align-items: center;
        display: flex;
    }
    .gameScoreCardWrapper{
        // display: flex;
        display: -webkit-box;
        overflow-x: auto;
        width: 100%;
        // overflow-style:marquee,panner;

        // overflow: hidden;
    }
}

