.mainHeader {
  position: relative;
  width: 100%;
  height: 259px;
  left: 0px;
  top: 0px;

  background: #ffffff;
}

.loginDiv {
  position: absolute;
  width: 75px;
  height: 22px;
  right: 320px;
  top: 16px;
}

.loginDivInner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;

  position: absolute;
  width: 75px;
  height: 22px;
  left: 0px;
  top: 0px;
}

.loginIcon {
  width: 22px;
  height: 22px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.loginText {
  width: 43px;
  height: 22px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */
  /* Text Color/Low Contrast */
  color: #828d9e;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.socialMainDiv {
  position: absolute;
  width: 228px;
  height: 22px;
  left: 320px;
  top: 16px;
}

.facebookDiv {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;

  position: absolute;
  width: 108px;
  height: 22px;
  left: 0px;
  top: 0px;
}

.facebookIcon {
  width: 22px;
  height: 22px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.facebookText {
  width: 76px;
  height: 22px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 138% */
  /* Text Color/Low Contrast */
  color: #828d9e;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.twitterDiv {
  position: absolute;
  left: 140px;
  top: 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  position: absolute;
  width: 86px;
  height: 22px;
}

.twitterIcon {
  width: 22px;
  height: 22px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.twitterText {
  width: 54px;
  height: 22px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 138% */
  /* Text Color/Low Contrast */
  color: #828d9e;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.line1 {
  position: absolute;
  height: 1px;
  left: 0px;
  right: 0px;
  top: 54px;
  background: #f1f3f6;
}

.oztagLogo {
  position: absolute;
  width: 116px;
  height: 60px;
  left: 320px;
  top: 93px;
}

// .dropdown {
//     box-sizing: border-box;

//     position: absolute;
//     width: 164px;
//     height: 52px;
//     left: 539px;
//     top: 95px;

//     /* Fill Color/BG */

//     background: #FFFFFF;
//     /* Border Color/#1 */

//     border: 1px solid #E2E5EA;
//     border-radius: 8px;
// }

.searchBar {
  box-sizing: border-box;

  position: absolute;
  height: 52px;
  left: 715px;
  right: 704px;
  top: 95px;

  /* Fill Color/BG */

  background: #ffffff;
  /* Border Color/#1 */

  border: 1px solid #e2e5ea;
  border-radius: 8px;
  width: 501px;
}

.searchIcon {
  position: absolute;
  width: 22px;
  height: 22px;
  right: 13px;
  top: 15px;
}

.searchText {
  position: absolute;
  width: 400px;
  height: 22px;
  left: 16px;
  top: 15px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  border: none;
  /* identical to box height, or 138% */

  /* Fill Color/Grey #2 */

  color: #828d9e;
}

.currency {
  position: absolute;
  width: 66px;
  height: 22px;
  right: 538px;
  top: 110px;
  border: none;
}

option {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  /* Text Color/Low Contrast */

  color: #828d9e;
}

.shoppingCardDiv {
  position: relative;
  width: 176px;
  height: 64px;
  // right: 338px;
  // top: 83px;
}

.cartlogo {
  box-sizing: border-box;
  position: absolute;
  left: 0%;
  right: 70.45%;
  top: 18.75%;
  bottom: 0%;
}

.cartText {
  position: absolute;
  height: 22px;
  left: 36.36%;
  right: 0%;
  top: calc(50% - 22px / 2 - 7px);

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  /* Text Color/Normal */

  color: #1e354e;
}

.cartPrice {
  position: absolute;
  height: 22px;
  left: 36.36%;
  right: 10.23%;
  top: calc(50% - 22px / 2 + 19px);

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  /* Text Color/Low Contrast */

  color: #828d9e;
}

.cartCounter {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 40px;
  top: 0px;
  background: #00b5ff;
  text-align: center;
  color: #ffffff;
  border-radius: 50%;
  align-items: center;
}

.line2 {
  position: absolute;
  height: 1px;
  left: 0px;
  right: 0px;
  top: 187px;
  background: #f1f3f6;
}

.line3 {
  position: absolute;
  height: 1px;
  left: 0px;
  right: 0px;
  top: 258px;
  background: #f1f3f6;
}

.menuList {
  width: 1052px;
  height: 22px;

  // left: 320px;
  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: justify;
  }

  li {
    display: inline;
  }
}

.mainMenu {
  position: absolute;
  top: 212px;
  text-align: -webkit-center;
  width: 100%;
  justify-content: center;
}
