@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap');


.App {
	font-family: 'Inter';
	position: relative;
	width: 100%;
	flex: auto;
	flex-direction: column;
	/* scale: 0.8; */
	/* width: 1920 px; */
}

.testtt * {
	box-sizing: border-box;
	padding: 0px;
	margin: 0px;
}

.testtt {
	display: flex;
	position: relative;
	padding: 20px 10px;
	flex-direction: column;
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Old versions of Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
	/* Non-prefixed version, currently supported by Chrome, Edge,*/
	border: none !important
}

.testtt .bar {
	display: flex;
}

.testtt .bar-left {
	width: 25%;
	background-color: #f0f0f0;
	border-radius: 10px 0px 0px 10px;
	box-shadow: inset 0px 0px 0px black !important;
	padding: 0px !important;
}

.testtt .bar-right {
	width: 25%;
	background-color: #f0f0f0;
	border-radius: 0px 10px 10px 0px;
	box-shadow: inset 0px 0px 0px black !important;
}

.testtt .bar-inner {
	background-color: #00B5FF !important;
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	justify-content: space-between;
	position: relative;
	border: solid 1px black;
	justify-content: space-between;
	box-shadow: inset 0px 0px 0px black !important;
}

.testtt .bar-inner-left {
	width: 50%;
}

.testtt .bar-inner-right {
	width: 50%;
}

.testtt .thumb {
	background-color: white;
	position: relative;
	z-index: 1;
	cursor: pointer;
}

.testtt .thumb::before {
	content: '';
	background-color: white !important;
	position: absolute;
	width: 20px;
	height: 20px;
	border: solid 1px #00B5FF;
	box-shadow: 0px 0px 0px black, inset 0px 0px 0px gray !important;
	border-radius: 50%;
	z-index: 1;
	margin: -8px -12px;
	cursor: pointer;
}

.testtt .input-type-range:focus+.thumb::after {
	content: '';
	position: absolute;
	top: -4px;
	left: -7px;
	width: 11px;
	height: 11px;
	z-index: 2;
	border-radius: 50%;
	border: dotted 1px black;
	box-shadow: 0px 0px 5px white, inset 0px 0px 10px black;
}

.testtt .caption {
	position: absolute;
	bottom: 35px;
	width: 2px;
	height: 2px;
	left: 1px;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: visible;
	display: none;
}

.testtt .thumb .caption * {
	position: absolute;
	min-width: 30px;
	height: 30px;
	font-size: 75%;
	text-align: center;
	line-height: 30px;
	background-color: #00B5FF !important;
	border-radius: 15px;
	color: white;
	box-shadow: 0px 0px 5px black;
	padding: 0px 5px;
	white-space: nowrap;
}

.testtt .thumb:active .caption {
	display: flex;
}

.testtt .input-type-range:focus+.thumb .caption {
	display: flex;
}

.testtt .input-type-range {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	opacity: 0;
	pointer-events: none;
}

.testtt .ruler {
	margin: 10px 0px -5px 0px;
	display: flex;
	/* display: none; */
	overflow: hidden;
}

.testtt .ruler .ruler-rule {
	border-left: solid 1px;
	border-bottom: solid 1px;
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	padding: 5px 0px;
}

.testtt .ruler .ruler-rule:last-child {
	border-right: solid 1px;
}

.testtt .ruler .ruler-sub-rule {
	border-left: solid 1px;
	/* border-bottom: solid 1px; */
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	padding: 3px 0px;
	bottom: 0px;
	margin-bottom: -5px;
}

.testtt .ruler .ruler-sub-rule:first-child {
	border-left: none;
}

.testtt .labels {
	display: flex;
	justify-content: space-between;
	padding: 0px;
	margin-top: 10px;
	margin-bottom: -20px;
	/* display: none; */
}

.testtt .label {
	font-size: 80%;
	display: flex;
	width: 1px;
	justify-content: center;
}

.testtt .label:first-child {
	justify-content: start;
}

.testtt .label:last-child {
	justify-content: end;
}

.testtt.zero-ranage-margin .thumb-left {
	right: 12px;
}

.testtt.zero-ranage-margin .thumb-right {
	left: 8px;
}

/* .css-b62m3t-container {
	@apply w-40 h-12
} */

.css-13cymwt-control {
	@apply w-40 h-12 rounded-lg
}

@layer components {
	.h5Normal {
		@apply text-[12px];
		@apply font-medium;
		@apply text-[#1E354E]
	}

	.pagination {
		@apply flex;
		@apply flex-row;
		@apply justify-center;
		@apply items-center;
	}

	.paginationButtonsActive {
		@apply px-4 py-2 text-white bg-[#00B5FF] decoration-transparent
	}

	.paginationButtons {
		@apply px-4 py-2 text-[#828D9E] border decoration-transparent
	}

	.nextButtons {
		@apply px-4 py-2 text-[#828D9E] ml-3 decoration-transparent
	}

	.previousButtons {
		@apply px-4 py-2 text-[#828D9E] mr-3 decoration-transparent
	}

	.sizeList {
		@apply inline-flex gap-2;


	}

	.sizeList li {
		@apply w-12 h-12 border rounded-lg flex items-center justify-center hover:border-[#00B5FF] cursor-pointer;
	}

	.h2Semibold {
		@apply text-4xl;
		@apply font-semibold;
		@apply text-[#1E354E]
	}

	.h1Semibold {
		@apply text-6xl;
		@apply font-semibold;
		@apply text-[#1E354E]
	}

	.h3Semibold {
		@apply text-2xl;
		@apply font-semibold;
		@apply text-[#1E354E]
	}

	.h4Medium {
		@apply text-[16px] font-medium text-[#1E354E];
	}

	.h4Semibold {
		@apply text-[16px] font-semibold text-[#1E354E];
	}

	.h4Regular {
		@apply text-[14px];
		@apply font-normal;
		@apply text-[#828D9E]
	}

	.checkbox {
		@apply bg-[white] h-5 w-5 rounded-md border mr-3 text-[#00B5FF] ring-0 focus:ring-white;
	}

	.h5Regular {
		@apply text-[14px];
		@apply font-normal;
		@apply text-[#1E354E]
	}

	.input {
		@apply py-3 px-5 border rounded-lg border-[#CECECE]
	}

	.counter {
		@apply w-20 px-5 py-3 border rounded-lg border-[#CECECE]
	}

	.cartTable {

		td,
		th {
			@apply py-5
		}
	}
	.listItem{
		@apply text-lg font-medium
	}
	.dropdown-menu{
		@apply z-50
	}

	.css-13cymwt-control{
		width: '100%' !important;
	}
}

@layer base {
	h5 {
		@apply text-lg;
		@apply font-normal;
		@apply text-[#828D9E]
	}
	li {
		@apply no-underline text-[#1E354E] text-[16px] font-semibold hover:border-b-2 border-[#00B5FF] pb-2 hover:transition-all cursor-pointer
	}

}

.leaflet-container {
	@apply w-full h-60 relative;
  }
.carousel-control-prev{
	justify-content: start !important;
	margin-left: 2%;
}
.carousel-control-next{
	justify-content: end !important;
	margin-right: 2%;
}
