.mainCatDiv {
    width: 1280px;
    height: 628px;
    // background-color: aquamarine;
    justify-content: center;
    align-items: center;
    display: flex;
    // flex: auto;
    scale: 0.8;

}

.backDiv {
    display: flex;
    flex: auto;
    justify-content: center;
    align-items: center;

}

.normalButton {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    /* identical to box height, or 156% */

    text-align: center;

    /* Fill Color/White */

    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    padding: 14px 36px;
    gap: 10px;
    background: #00B5FF;
    border-radius: 8px;
    border: none;
}

.leftDiv {
    width: 50%;
    direction: ltr;
}

.rightDiv {
    width: 50%;
    direction: rtl;
}

.headerText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    /* identical to box height */


    /* Text Color/Normal */

    color: #1E354E;
    padding: 14px 0px;

}

.normalText {
    /* Heading 5 / Regular */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* or 138% */
    padding: 14px 0px;


    /* Text Color/Low Contrast */

    color: #828D9E;
}