.featuredItemOffer {
    box-sizing: border-box;
    // flex:2;
    width: 411px;
    height: 598px;

    /* Border Color/#1 */

    border: 1px solid #E2E5EA;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-decoration: none;
}

.specialItem {
    margin: 32px;
}

.productText {
    /* Heading 4 / SemiBold */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    /* identical to box height, or 156% */

    text-align: center;

    /* Text Color/Normal */

    color: #1E354E;
}

.timeField {
    width: 52px;
    height: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
}

.innerTimeField {
    /* Fill Color/BG */
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    /* Border Color/#1 */

    border: 1px solid #E2E5EA;
    border-radius: 8px;
}

.timeText {
    /* Heading 5 / Medium */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 138% */

    text-align: center;

    /* Text Color/Normal */

    color: #1E354E;
    margin: 15px 0;

}

.timeTypeText {
    /* Caption / Regular */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: center;

    /* Text Color/Low Contrast */

    color: #828D9E;
    margin-top: 6px;
}
.timeDiv{
    display: flex;
    width: 300px;
    justify-content: space-evenly;
}
.mt1{
    margin-top: 12px;
}