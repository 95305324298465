.downloadDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    scale: 0.8;

    .heading2 {
        /* Heading 2 / SemiBold */

        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        /* identical to box height */

        text-align: center;

        /* Text Color/Normal */

        color: #1E354E;
    }

    .heading5 {
        /* Heading 5 / Regular */

        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        /* or 138% */

        text-align: center;

        /* Text Color/Low Contrast */

        color: #828D9E;
    }
    .buttonsDiv{
        margin-top: 50px;
        max-width: 500px;
        margin-bottom: 50px;
    }
    .pointer{
        cursor: pointer;
    }
}